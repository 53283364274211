.vertical-timeline * {
    box-sizing: border-box;
  }
  
  .vertical-timeline {
    width: 95%;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
  }
  .vertical-timeline::after {
    /* clearfix */
    content: '';
    display: table;
    clear: both;
  }
  
  .vertical-timeline::before {
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: white;
  }
  
  @media only screen and (min-width: 1170px) {
    .vertical-timeline.vertical-timeline--two-columns {
      width: 90%;
    }
  }
